import {Component, Input} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-success-message-vitals',
  templateUrl: './success-message-vitals.component.html',
  styleUrls: ['./success-message-vitals.component.scss']
})
export class SuccessMessageVitalsComponent {

  @Input() messageList: any;

  constructor(public modalService: NgbModal) {
    console.log('message',this.messageList)
  }
}
