import { Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ApplicationFacadeService, SignInAction} from "@daisy2/core";
import {take} from "rxjs";

@Component({
  selector: 'app-lock-modal',
  templateUrl: './lock-modal.component.html',
  styleUrls: ['./lock-modal.component.scss']
})
export class LockModalComponent {

  constructor(public activeModal:NgbActiveModal,
              public applicationFacadeService:ApplicationFacadeService,) {
  }


  lockForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('', Validators.required,),
    twoFactorToken: new FormControl('')

  })

  async ClickedUnLockEventHandler(){
    console.log('call')
    if (this.lockForm.valid){
      let application:any = await this.applicationFacadeService.application$.pipe(take(1)).toPromise()
      console.log('hossein app',application)
      this.lockForm.patchValue({
        username: application.username
      })
      this.applicationFacadeService.dispatch(new SignInAction(this.lockForm.value));
    }else {
      this.lockForm.controls['password'].setErrors({invalidPassword:true})
    }
  }
}
