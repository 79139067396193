import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoDecimal]'
})
export class NoDecimalDirective {

  private regex: RegExp = new RegExp(/^\d*$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor() {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow special keys and control keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    // Prevent default behavior if the key is not a number
    if (!this.isNumber(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text') || '';

    if (!this.regex.test(pastedText)) {
      event.preventDefault();
    }
  }

  private isNumber(char: string): boolean {
    return this.regex.test(char);
  }
}
