import {NgModule} from '@angular/core';
import { AboutNursdaisyComponent } from './components/about-nursdaisy/about-nursdaisy.component';
import { CalendarNavigatorBaseComponent } from './components/calendar-navigator-base/calendar-navigator-base.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { FeedBackComponent } from './components/feed-back/feed-back.component';
import { SpinnerLoadingComponent } from './components/spinner-loading/spinner-loading.component';
import { LockModalComponent } from './components/lock-modal/lock-modal.component';
import {ReactiveFormsModule} from "@angular/forms";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import { LockDirective } from './components/lock.directive';
import { HowDoYouFeelModalComponent } from './components/how-do-you-feel-modal/how-do-you-feel-modal.component';
import { CheckPermissionsDirective } from './directives/check-permissions.directive';
import {SharedBaseModule} from "@daisy2/core";
import { ChatBotComponent } from './components/chat-bot/chat-bot.component';
import { SuccessMessageVitalsComponent } from './components/success-message-vitals/success-message-vitals.component';
import { DecimalPlaceDirective } from './directives/decimal-place.directive';
import { NoDecimalDirective } from './directives/no-decimal.directive';



@NgModule({
    declarations: [


    AboutNursdaisyComponent,
          CalendarNavigatorBaseComponent,
          DatePickerComponent,
          ErrorMessageComponent,
          FeedBackComponent,
          SpinnerLoadingComponent,
          LockModalComponent,
          LockDirective,
          HowDoYouFeelModalComponent,
          CheckPermissionsDirective,
          ChatBotComponent,
          SuccessMessageVitalsComponent,
          DecimalPlaceDirective,
          NoDecimalDirective
  ],
  imports: [
    ReactiveFormsModule,
    NgIf,
    SharedBaseModule,
    JsonPipe,
    NgForOf


  ],
  exports: [
    LockDirective,
    CheckPermissionsDirective,
    DecimalPlaceDirective,
    NoDecimalDirective


  ]
})
export class SharedModule {
}
