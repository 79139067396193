<div class="nd-container">
  <div class="nd-header-section">
    <div class="nd-page-title">Login</div>
    <div (click)="activeModal.close()" class="nd-close-modal-btn nd-cursor-pointer">
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon-interactive/close">
          <g id="icon/close">
            <path id="Vector"
                  d="M16.5549 5.46541L15.3799 4.29041L10.7215 8.94874L6.06318 4.29041L4.88818 5.46541L9.54652 10.1237L4.88818 14.7821L6.06318 15.9571L10.7215 11.2987L15.3799 15.9571L16.5549 14.7821L11.8965 10.1237L16.5549 5.46541Z"
                  fill="#064491"/>
          </g>
        </g>
      </svg>
    </div>
  </div>
  <div class="nd-modal-description">
    To access this functionality, you must log in
  </div>

  <div class="nd-modal-body">
    <form [formGroup]="lockForm" (ngSubmit)="ClickedUnLockEventHandler()">
      <input class="nd-input" formControlName="password"  placeholder="Enter password" type="text" autocomplete="new-password">
      <div class="nd-password-error" *ngIf="lockForm.controls['password'].hasError('invalidPassword')">Password invalid!</div>
      <button class="nd-btn-primary" type="submit">LOGIN</button>
    </form>
  </div>

</div>
