import { Component } from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PatientFacadeService} from "@daisy2/core";
import {Router} from "@angular/router";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-request-symptom-triage-modal',
  templateUrl: './request-symptom-triage-modal.component.html',
  styleUrls: ['./request-symptom-triage-modal.component.scss']
})
export class RequestSymptomTriageModalComponent {

  constructor(public modalService:NgbActiveModal,
              private patientFacadeService:PatientFacadeService,
              private router:Router) {
  }

 async ClickedTriageSymptomEventHandler(){
    let currentState = await firstValueFrom(this.patientFacadeService.patientState$);
   this.modalService.dismiss(currentState.currentTicket)
  }

}
