import {Directive, ElementRef, HostBinding, HostListener, Input, Optional} from '@angular/core';
import {ApplicationFacadeService} from "@daisy2/core";
import {take} from "rxjs";
import {RouterLink, RouterLinkWithHref} from "@angular/router";

@Directive({
  selector: '[appCheckPermissions]'
})
export class CheckPermissionsDirective {
  permissionUiIdList:any = {
    'feeling_route':'Symptoms & Vitals',
    'request_route':'Request',
    'schedule_route':'Daily Schedule',
    'carePlan_route':'Care Plan & Meds',
    'reportCard_route':'Report Card',
    'pillBox_route':'Care Plan & Meds',
    'add_pillBox_btn':'Care Plan & Meds',
    'vital_submit_btn':'Symptoms & Vitals',
    'vital_submit_btn-parent':'Symptoms & Vitals',
    'add_schedule_btn_parent':'Daily Schedule',
    'add_schedule_btn':'Daily Schedule',
    'add_schedule_note_btn':'Daily Schedule',
    'schedule_change_status1':'Daily Schedule',
    'schedule_change_status2':'Daily Schedule',
    'create_ticket_btn':'Request',


  }
  constructor(private applicationFacadeService:ApplicationFacadeService,
              private el: ElementRef,
              @Optional() routerLink: RouterLink,
              @Optional() routerLinkWithHref: RouterLinkWithHref,) {
    let tagId = this.el.nativeElement.id


    this.getPermissionsList(this.permissionUiIdList[tagId]).then(result=>{
      console.log('permission',result)
      if (!result.readStatus){
        this.el.nativeElement.style.opacity = '0.3'
        this.el.nativeElement.style.cursor = 'not-allowed'
        const link =  routerLink || routerLinkWithHref;
        const onClick = link.onClick;
        link.onClick = (...args) => {

          return true
        };
      }else if (result.writeStatus == false && result.readStatus == false){

        this.el.nativeElement.style.opacity = '0.3'
        this.el.nativeElement.style.cursor = 'not-allowed'
        this.el.nativeElement.disabled = true
      }
    })




  }

  @HostListener('click', ['$event'])
  onClick(event:any) {
    if (this.el.nativeElement.style.cursor == 'not-allowed') {
      event.preventDefault();

    }
  }


 async getPermissionsList(id:any){
    const applicationState =await this.applicationFacadeService.application$.pipe(take(1)).toPromise()
    const permissionsList = applicationState.currentPermissions
    let permission:any = permissionsList.find((item:any)=>item.name == id)
   return permission
  }



}

