import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDecimalPlace]'
})
export class DecimalPlaceDirective {

  private regex: RegExp = new RegExp(/^\d+(\.\d{0,1})?$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow special keys and control keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);

    // Check if the new value matches the regex pattern
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('focusout', ['$event.target.value'])
  onFocusOut(value: string) {
    if (value) {
      this.formatInput(value);
    }
  }

  private formatInput(value: string) {
    const formattedValue = parseFloat(value).toFixed(1);

    // Set the formatted value back to the input element
    this.el.nativeElement.value = formattedValue;

    // Update the FormControl value
    this.control.control?.setValue(formattedValue, { emitEvent: false });
  }
}
