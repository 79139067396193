import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {NotFoundComponent} from "./not-found/not-found.component";
import Utilities from "./shared/utilities";

const desktop_routes: Routes = [
  {path:'',loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)},

  { path: '**', pathMatch: 'full',
    component: NotFoundComponent },
];

const mobile_routes: Routes = [
  {path:'',loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)},
  { path: '**', pathMatch: 'full',
    component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot((Utilities.screen_manager() ? mobile_routes : mobile_routes))],
  exports: [RouterModule]
})
export class AppRoutingModule { }
