import {NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from "./shared/shared.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {TokenInterceptorService} from "./token.interceptor";
import {
  API_ADDRESS, API_MEDIA,
  APP_VERSION,
  ApplicationBaseModule, ApplicationFlowService,
  ApplicationUIService as ApplicationBaseUIService, PatientBaseModule,
  PatientUIService as PatientBaseUIService
} from "@daisy2/core";
import {ApplicationUIService} from "./services/application-ui.service";
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {PatientUIService} from "./services/patient-ui.service";

import {PatientUIKitService as PatientBaseUIKitService} from "@daisy2/core";
import {PatientUIKitService} from "./services/patient-ui-kit.service";
import {NgSelectModule} from "@ng-select/ng-select";
import {IonicModule} from "@ionic/angular";
import {NgbActiveModal, NgbModalModule, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import packageFile from '../../package.json';
import {environment} from "../environments/environment";
import { ServiceWorkerModule } from '@angular/service-worker';
import {InfiniteScrollModule} from "ngx-infinite-scroll";

declare global {
  interface Window {
    __env: {
      apiUrl: string;
      chatUrl: string;
      notificationHubUrl: string;
      videoHubUrl: string;
      mediaUrl:string;
      // Add other properties if needed
    };
  }
}

@NgModule({
  declarations: [
    AppComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    InfiniteScrollModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    ApplicationBaseModule,
    PatientBaseModule,
    NgSelectModule,
    NgbModule,//TODO : FJ - this line added becuase popup of add schedule not work - I should read more about it I resolve the problem
    IonicModule.forRoot(), ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: !isDevMode(),
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
}),

  ],
  providers: [
    NgbActiveModal,
    {provide: API_ADDRESS, useValue: environment.apiUrl},
    {provide: API_MEDIA, useValue: environment.mediaUrl},
    {provide: APP_VERSION, useValue: packageFile.version},
    {provide: ApplicationBaseUIService, useClass: ApplicationUIService},
    {provide: PatientBaseUIService, useClass: PatientUIService},
    {provide: PatientBaseUIKitService, useClass: PatientUIKitService},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private applicationFlowService: ApplicationFlowService,
              private applicationUIService: ApplicationUIService,
              private patientUIKitService: PatientUIKitService,) {

  }

}

//
