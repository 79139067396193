import {Component} from '@angular/core';

import * as moment from "moment";
import {environment} from "../environments/environment";



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nursedaisy.patient.web';


  constructor() {

    if (environment.production) {
      console.log = function () {
      };
      console.error = function () {
      };
    }




  }





  GroupByKeys(list: any, key: any) {
    return list.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }


}




export class OnCallScheduleModel {
  constructor(
    public dayOfWeek: number,
    public startTime: string,
    public endTime: string,
    public uiTime: string
  ) {
  }

}
