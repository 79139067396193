import {Injectable} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {DaisySuccessDialogComponent, ScheduleManipulationDesktopComponent} from "@daisy/uikit";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Utilities from "../shared/utilities";
import {
  TermConditionMobileComponent
} from "../authentication/components/term-condition-mobile/term-condition-mobile.component";
import {ApplicationFacadeService} from "@daisy2/core";
import {take} from "rxjs";



@Injectable({
  providedIn: 'root',
})
export class ApplicationUIService {

  constructor(private toastr: ToastrService,
              private modalService: NgbModal,
              private router: Router,
              private applicationFacadeService:ApplicationFacadeService) {}

  public PatientSuccessSignIn():void{

    this.router.navigate(['initialization']);
    this.modalService.dismissAll()

    }
  public async PatientFailureSignIn(){
      console.log('PatientFailureLogin');
      let application:any = await this.applicationFacadeService.application$.pipe(take(1)).toPromise()
      if (application.isLock){
        this.toastr.error('The password is incorrect.', 'Operation Failed');

      }else {
        this.toastr.error('The username or password is incorrect.', 'Operation Failed');

      }


  }

  public PatientSuccessInitialization(){

    if(Utilities.screen_manager()){
      this.router.navigate(['/panel/dashboard'],{replaceUrl:true});
    } else {
      this.router.navigate(['/panel/feeling/symptoms'],{replaceUrl:true});
    }


  }
  public PatientSuccessSignUp():void{
    console.log('redirected from base..');
    this.toastr.success('', 'Your registration has been successful.');
    this.router.navigate(['/']);
  }
  public PatientFailureSignUp(message:string):void{
    this.toastr.error(message, 'Operation Failed');

  }
  public PatientSuccessForgetPassword(){
    this.toastr.success('', 'Password Reset Email Has Been Sent,please check your mailbox.');
    this.router.navigate(['/login'])
  }
  public PatientSuccessResetPassword(){
    this.toastr.success('', 'Your Password Has Been Successfully Changed.');
    this.router.navigate(['/login'])
  }
  public PatientSuccessUpdateProfile(){
    let modalRef =this.modalService.open(DaisySuccessDialogComponent, { centered: true });
      modalRef.componentInstance.message = "Your account has been updated.";
    }
  public patientActiveOTPLogin(): void {
    this.router.navigate(['/otp']);
  }
  public PatientSuccessFeedback():void {
    let modalRef =this.modalService.open(DaisySuccessDialogComponent, { centered: true });
    modalRef.componentInstance.message = "Thank you for taking the time to provide feedback. We appreciate hearing from our users";
  }

  public UnAcceptedSuccessFeedback():void {
    this.modalService.open(TermConditionMobileComponent,{scrollable:true}).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  public SwitchUserEventHandler(){
    this.router.navigate(['switch-account'],{replaceUrl:true})
  }

  public SwitchUserSuccessfully(){
    this.router.navigate(['/panel','dashboard'],{replaceUrl:true})
  }

}
