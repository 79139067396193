<div class="nd-modal-container">
  <div class="nd-modal-header"></div>
  <div class="nd-modal-body">
    <div class="nd-modal-content">Choose a symptom, complete the triage, and then your request will be created.</div>
    <div class="nd-modal-btn-action-parent">
      <button class="nd-cancel-btn-secound w-50" (click)="modalService.close()" [routerLink]="['/panel','tickets']">CANCEL</button>
      <button class="nd-submit-btn w-50" (click)="ClickedTriageSymptomEventHandler()" >OK</button>
    </div>
  </div>
</div>
