import { Component } from '@angular/core';

@Component({
  selector: 'app-term-condition-mobile',
  templateUrl: './term-condition-mobile.component.html',
  styleUrls: ['./term-condition-mobile.component.scss']
})
export class TermConditionMobileComponent {

}
