import {Directive, ElementRef, HostBinding, HostListener} from '@angular/core';
import {ApplicationFacadeService} from "@daisy2/core";
import {take} from "rxjs";

@Directive({
  selector: '[appLock]'
})
export class LockDirective {

  application:any
  constructor(private el:ElementRef,
              private applicationFacedService:ApplicationFacadeService) {
    this.checkApplicationState()
  }


 async checkApplicationState(){
    this.application = await this.applicationFacedService.application$.pipe((take(1))).toPromise()
   if (this.application.isLock){
     this.el.nativeElement.style.opacity = 0.5
     this.el.nativeElement.style.cursor = 'not-allowed'
   }
  }


  // @HostBinding('disabled') disabled = true;
  @HostBinding('disabled') get test() {
    if (this.el.nativeElement.tagName == 'BUTTON'){
      if (this.application.isLock){
        this.el.nativeElement.style.opacity = '0.3'
        this.el.nativeElement.style.cursor = 'not-allowed'

        return true
      }
    }
    return false
  }

}
