import { Component } from '@angular/core';

@Component({
  selector: 'app-calendar-navigator-base',
  templateUrl: './calendar-navigator-base.component.html',
  styleUrls: ['./calendar-navigator-base.component.scss']
})
export class CalendarNavigatorBaseComponent {

}
