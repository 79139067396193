import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {catchError, finalize, Observable, tap, throwError} from "rxjs";


import {Injectable} from "@angular/core";
import {PatientUIService} from "./services/patient-ui.service";
import {ApplicationFacadeService, SignOutAction} from "@daisy2/core";
import {ToastrService} from "ngx-toastr";


@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
    constructor(private patientUiService:PatientUIService,
                private applicationFacadeService:ApplicationFacadeService,
                private notification:ToastrService) {
    }
    //tst
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      let accessToken=localStorage.getItem('token');
      if (request.url.startsWith('https://wcnd-dev-test.nyc3.digitaloceanspaces.com')){
        return next.handle(request)
      }

      if(request.url.includes('UploadProfileImage') || request.url.includes('AddSymptomNoteAttachment')  || request.url.includes('CreateFeedback')) {
        console.log(request.body instanceof FormData)
        const authReq = !!accessToken ? request.clone({
          headers: request.headers.set('Authorization', `Bearer ${accessToken}`),

        }) : request;
        console.log('auth',authReq)
        return next.handle(authReq);
      }

      const authReq = !!accessToken ? request.clone({
        headers: request.headers.set('Authorization', `Bearer ${accessToken}`).set('Content-Type', 'application/json'),
        body:  request.url.endsWith('api/Permission/GetUserPermissions') ? request.body : this.bodyManipulator(request.body,accessToken)
      }) : request;


      return next.handle(authReq).pipe(
        tap(event=>{
        }),
        catchError((err:any) => {
          console.log('error',)

          if (err.status == 401 && !request.url.includes('/api/Token/CreateToken')){
            this.notification.error('Please login first.','Authorization')
            this.applicationFacadeService.dispatch(new SignOutAction(null));
          }

          throw err
        })
      )
        // // const token='';
        // const token = await this.applicationFacadeService$.application$;
        //
        // if (token) {
        //     // If we have a token, we set it to the header
        //     request = request.clone({
        //         setHeaders: {Authorization: `Authorization token ${token}`}
        //     });
        // }
        //
        // return next.handle(request).pipe(
        //     catchError((err:any) => {
        //         if (err instanceof HttpErrorResponse) {
        //             if (err.status === 401) {
        //                 // redirect user to the logout page
        //             }
        //         }
        //         return throwError(err);
        //     })
        // )
    }


    bodyManipulator(body:any,currentUser:any) {
        console.log('==>>>>>>>.',body)
        let newBody;
        if(typeof body !="object")
            newBody = Object.assign({}, JSON.parse(body));
        else
            newBody = Object.assign({},body);
        if(currentUser.currentPatient != null) {
            newBody["userId"] = currentUser.currentPatient
        }
        return newBody;
    }
}
