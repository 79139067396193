
export default class Utilities {
  static screen_manager() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return false;
      return "tablet";
    } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return true;
      return "mobile";
    }
    return false;
    // var injector = ReflectiveInjector.resolveAndCreate([DeviceDetectorService]);
    // var deviceService = injector.get(DeviceDetectorService);
    // console.log('example of out mobile....', deviceService.isMobile())
    // //Utilities.mobileDevice = this.deviceService.isMobile() || this.deviceService.isTablet();
    // console.log('example of out mobile....', deviceService.isTablet());
    // return this.mobileDevice;
    // return window.innerWidth < 768;
    //return this.deviceService.isMobile() || this.deviceService.isTablet()
  }

  static GroupByKey(array: any, key: any) {
    return array
      .reduce((hash: any, obj: any) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, {[obj[key]]: (hash[obj[key]] || []).concat(obj)})
      }, {})
  }


  static CheckPermission(name: any, list: any) {
    if (name == 'feeling') {

    }

    let permission = {
      setting: {read: true, write: true},
      feeling: {read: true, write: true},
      schedule: {read: true, write: true},
      request: {read: true, write: true},
      carePlane: {read: true, write: true},
      reportCard: {read: true, write: true},
    }
  }

  // static intensityDic: any = {
  //   '0-10': {range: '0-10',systemRange:'10', component: TenSliderComponent},
  //   '0-10plus': {range: '0-10plus',systemRange:' 10+', component: TenPlusSliderComponent},
  //   '0-10pain': {range: '0-10pain',systemRange:'10', component: PainTenSliderComponent},
  //   'RR': {range: 'RR',systemRange:'RR', component: RespiratoryRateComponent},
  //   'BT': {range: 'BT',systemRange:'BT', component: BodyTemperatureComponent},
  //   'HR': {range: 'HR',systemRange:'HR', component: HeartRateComponent},
  //   'BP': {range: 'BP',systemRange:'BP', component: BloodPressureComponent},
  //   'OX': {range: 'OX',systemRange:'OX', component: BloodOxygenComponent}
  // }


}
