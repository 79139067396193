<div class="nd-modal-container" draggable="true">
  <div class="nd-header-modal">
    <div class="nd-chat-bot-title">Chat Bot</div>
    <div class="nd-drag-handler">...</div>
  </div>
  <div class="nd-modal-body">
    <div class="nd-chat-content"></div>
    <div class="nd-message-input">

      <div>
        <label for="ATTACH_FILE"></label>
        <input type="file" class="d-none" id="ATTACH_FILE">
      </div>

      <form>
        <div class="nd-input-group">
          <input type="text" placeholder="Type your message here.">
          <button></button>
        </div>

      </form>
    </div>
  </div>
</div>
