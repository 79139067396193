import { Component } from '@angular/core';

@Component({
  selector: 'app-about-nursdaisy',
  templateUrl: './about-nursdaisy.component.html',
  styleUrls: ['./about-nursdaisy.component.scss']
})
export class AboutNursdaisyComponent {

}
