<div class="nd-success">
  <div class="nd-cross" (click)="modalService.dismissAll()">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g id="icon/close">
        <path id="Vector" d="M15.8334 5.3415L14.6584 4.1665L10.0001 8.82484L5.34175 4.1665L4.16675 5.3415L8.82508 9.99984L4.16675 14.6582L5.34175 15.8332L10.0001 11.1748L14.6584 15.8332L15.8334 14.6582L11.1751 9.99984L15.8334 5.3415Z" fill="#2B641E"/>
      </g>
    </svg>
  </div>
  <div class="d-flex align-items-center flex-column justify-content-center">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
        <g id="icon/done">
          <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M33.9997 3.45654C17.1314 3.45654 3.4563 17.1317 3.4563 33.9999C3.4563 50.8682 17.1314 64.5433 33.9997 64.5433C50.868 64.5433 64.5431 50.8682 64.5431 33.9999C64.5431 17.1317 50.868 3.45654 33.9997 3.45654ZM47.2389 28.8353C47.4827 28.5567 47.6682 28.2321 47.7847 27.8806C47.9012 27.5292 47.9462 27.158 47.9171 26.7889C47.888 26.4198 47.7854 26.0602 47.6153 25.7314C47.4452 25.4025 47.2111 25.111 46.9266 24.874C46.6422 24.6369 46.3132 24.4592 45.9591 24.3512C45.605 24.2432 45.2328 24.2071 44.8645 24.245C44.4962 24.283 44.1392 24.3942 43.8145 24.5721C43.4898 24.7501 43.204 24.9911 42.9739 25.2812L31.0342 39.606L24.8561 33.4252C24.3324 32.9194 23.631 32.6395 22.903 32.6458C22.175 32.6522 21.4785 32.9442 20.9637 33.459C20.4489 33.9738 20.1569 34.6702 20.1506 35.3983C20.1442 36.1263 20.4241 36.8277 20.9299 37.3514L29.2599 45.6814C29.5327 45.9541 29.8594 46.1669 30.219 46.3063C30.5787 46.4458 30.9634 46.5087 31.3487 46.4912C31.734 46.4737 32.1115 46.3761 32.457 46.2047C32.8025 46.0332 33.1085 45.7916 33.3555 45.4954L47.2389 28.8353Z" fill="#84D65A"/>
        </g>
      </svg>
    </div>
    <div class="nd-title-success">
      Success!!!
    </div>
      <div class="d-flex flex-column align-items-start">
        <div class="nd-text-success text-start" *ngFor="let message of messageList">
          {{message}}
        </div>
      </div>


  </div>
</div>
